import moment from "moment";
import React, {Fragment, useEffect, useState} from "react";
import {Button, Icon, Pagination, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import {GameStatuses} from "../../../../enums/gameStatus";
import {
    deleteDartsAdcGame, getAdcDartsGames,
    getAdcDartsGamesInit, getOneDartsAdcGame, setActivePage, setSelectedAdcDartsGame
} from "../../../../store/cms/darts/adcGames/cmsAdcGamesSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {DartsAdcGamesData} from "../../../../store/cms/darts/adcGames/types";
import DartsAdcGamesForm from "./DartsAdcGamesForm";
import DartsAdcGamerFilter from "./DartsAdcGamerFilter";
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";
import "./styles.scss";

const DartsAdcGamesTable = () => {
    const dispatch = useAppDispatch();
    const {
        games,
        isLoading,
        filterValues,
        noContent,
        pageNum,
        pageSize,
        totalPages
    } = useAppSelector((state) => state.cmsAdcGames);
    const [isShowForm, setIsShowForm] = useState(false);
    const [isShowFilter, setIsShowFilter] = useState(false);
    const {user} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getAdcDartsGamesInit());
    }, []);

    useEffect(() => {
        const params = {...filterValues, pageNum, pageSize}
        dispatch(getAdcDartsGames(buildQueryString(params)))
    }, [filterValues]);


    const editHandler = (id: number) => {
        setIsShowForm(true);
        dispatch(getOneDartsAdcGame(id));
    }

    const generateButtons = (game: DartsAdcGamesData) => {
        if (game.status === GameStatuses.Cancelled || game.status === GameStatuses.End) return
        return (
            <Fragment>
                <DartsDeleteModal
                    id={game.id}
                    deleteAction={(id: number) => deleteDartsAdcGame(id)}
                    confirmText={"Are you sure you want to delete your handler?"}
                    titleModal={"Delete your Game"}
                />
                <Icon
                    name='pencil alternate'
                    link
                    onClick={() => editHandler(game.id)}
                    size="small"
                    color="grey"
                >
                </Icon>
            </Fragment>
        );
    };

    const closeFormHandler = () => {
        setIsShowForm(false);
        dispatch(setSelectedAdcDartsGame(null))
    }

    const closeFilter = () => {
        setIsShowFilter(false)
    }
    const showForm = () => {
        setIsShowForm(true);
    }
    const onOpenFilter = () => {
        setIsShowFilter(true);
    };

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setActivePage(activePage))
        const params = {...filterValues, pageNum: activePage, pageSize}
        dispatch(getAdcDartsGames(buildQueryString(params)));
    }

    return (
        <div>
            <div className="cms-action-btns-inner">
                <div>
                    <Button color="yellow" onClick={onOpenFilter}>
                        Filter
                    </Button>
                    <Button disabled={isLoading} color="green" id="add_game" onClick={showForm}>Create game</Button>
                </div>
            </div>
            {isLoading ? <DimmerLoader/> :
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">
                                    Id
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">
                                Competition
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Home player
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Away player
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Date start
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Legs to win
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {games?.map((game) => {
                            return (
                                <Table.Row key={game.id}>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">
                                            {game?.id}
                                        </Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">
                                        {game?.competitionName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{game?.location}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game?.playerHomeName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game?.playerAwayName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {moment(game?.timeStart)
                                            .local()
                                            .format("YYYY-MM-DD hh:mm:ss")}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game?.legsToWin}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game?.statusName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game?.status === GameStatuses.NotActive && generateButtons(game)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>}
            {isShowForm &&
                <DartsAdcGamesForm
                    closeHandler={closeFormHandler}
                />
            }
            {isShowFilter &&
                <DartsAdcGamerFilter closeFilter={closeFilter}/>
            }
            {!noContent && (
                <Pagination
                    floated="right"
                    size="mini"
                    activePage={pageNum}
                    totalPages={totalPages}
                    onPageChange={(e, {activePage}) => onChangePager(activePage)}
                />
            )}
        </div>
    );
};

export default DartsAdcGamesTable;
