import "./styles.scss";
import React, {useEffect, useState} from "react";
import {Container, Grid, Icon} from "semantic-ui-react";
import {GameStatuses} from "../../../../enums/gameStatus";
import {useAppSelector} from "../../../../hooks/hooks";
import {dartsGameStatuses} from "../../../../constants/gameStatuses";
import TimedComponent from "./helpers/TimedComponet";

export const DartboardHeader: React.FC = React.memo(() => {
    const {
        allInformation: {
            liveScore: {
                header: {
                    scores,
                    legs,
                    turnShots,
                    players,
                    status,
                    legNum,
                }
            }
        }
    } = useAppSelector((state) => state.liveScoreInfo)

    const playersScores = scores;
    const [currentStatus, setCurrentStatus] = useState('');
    useEffect(() => {
        if (status) {
            const statusString = dartsGameStatuses?.find(
                (item) => item.key === status
            );
            if (statusString?.value) {
                setCurrentStatus(statusString.value)
            }
        }
    }, [status])

    const notThrownDart = <Icon name="location arrow" fitted/>;
    const getPlayerScoresElement = (playerScore: number[]) => {
        if (playerScore) {
            const firstThrow = playerScore[0] ? playerScore[0] : notThrownDart;
            const secondThrow = playerScore[1] ? playerScore[1] : notThrownDart;
            const thirdThrow = playerScore[2] ? playerScore[2] : notThrownDart;
            const totalRoundScore = playerScore.reduce(
                (val, round) => val + round, 0);
            return (
                <div className="header__game-info__score__rounds" key="current">
                    <div className="throw">{firstThrow}</div>
                    <div className="throw">{secondThrow}</div>
                    <div className="throw">{thirdThrow}</div>
                    <div className="throw round-total">{totalRoundScore}</div>
                </div>
            );
        }
    }

    const playersScoresElements = getPlayerScoresElement(turnShots?.shots);

    const showPlayerScores = (playerId: number, scoresElement: any) => {
        if (turnShots?.isEnded && turnShots?.playerId === playerId) {
            return <TimedComponent component={scoresElement} startState={true}/>
        }
        if (turnShots?.isEnded && turnShots?.playerId !== playerId) {
            const element =
                <div className="header__game-info__score__rounds" key="current">
                    <div className="throw">{notThrownDart}</div>
                    <div className="throw">{notThrownDart}</div>
                    <div className="throw">{notThrownDart}</div>
                    <div className="throw round-total">{0}</div>
                </div>
            return <TimedComponent component={element} startState={false}/>
        }
        if (turnShots?.playerId === playerId) {
            return scoresElement
        }
        return null
    }

    return (
        <div className={"ls-header"}>
            {<Container className="header">
                <Grid columns={3} className="header__game-info">
                    <Grid.Column width={4} id="player_home" className="header__game-info__home">
                        {players[0] ? players[0].name : ""}
                    </Grid.Column>
                    <Grid.Column width={8} className="header__game-info__score">
                        <Grid>
                            <Grid.Row columns={5} className="np centered-v">
                                <Grid.Column textAlign="left">
                                    <div className={"left-shots"}>
                                        {showPlayerScores(players[0].id, playersScoresElements)}
                                    </div>
                                </Grid.Column>
                                <Grid.Column className="current-points" id="left_score">
                                    {playersScores !== null ? playersScores[0] : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <Grid
                                        className="header__game-info__score__short"
                                        textAlign="center"
                                    >
                                        <Grid.Row className="header__game-info__score__short__status">
                                            {<span
                                                id="game_status" className="centered-h">{currentStatus}</span>}
                                        </Grid.Row>
                                        <Grid.Row className="header__game-info__score__short__current">
                                        <span
                                            id="leg_home"
                                            className="header__game-info__score__short__current__home">{legs[0]}
                                        </span>
                                            <span className="header__game-info__score__short__current__semi">:</span>
                                            <span
                                                id="leg_away"
                                                className="header__game-info__score__short__current__away">{legs[1]}
                                        </span>
                                        </Grid.Row>
                                        <Grid.Row className="header__game-info__score__short__round">
                                            {(status !== GameStatuses.End && status !== GameStatuses.PreEnd) && (
                                                <span
                                                    id="round_num"
                                                    className="centered-h round-name">{legNum?`Leg ${legNum}`:""}
                                            </span>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column className="current-points" id="right_score" textAlign="left">
                                    {playersScores !== null ? playersScores[1] : null}
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    {showPlayerScores(players[1].id, playersScoresElements)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={4} id="player_away" className="header__game-info__away">
                        {players[1] ? players[1].name : ""}
                    </Grid.Column>
                </Grid>
            </Container>}
        </div>
    );
})
