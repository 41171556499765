import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    getScoreboardStatsInit,
} from "../../../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import GameScoreFooter from "../GameScores/components/GameScoreFooter";
import {setCurrentThrowerStyles} from "../GameScores/helpers/setCurrentThrowerStyles";
import "./styles.scss";
import {DartsGameTypes} from "../../../../enums/gameEvents";

const ShootOutGameScores: React.FC = () => {
    const {gameId} = useParams<{ gameId: string,tourId:string }>();
    const dispatch = useAppDispatch();
    const [animationAway180, setAnimationAway180] = useState(false);
    const [animationHome180, setAnimationHome180] = useState(false);
    const {scoreBoardAndStatsInfo: {bestOfXLegs}} = useAppSelector((state) => state.scoreboardAndStats);
    const statistics = bestOfXLegs

    useEffect(() => {
        if (statistics.is180PlayerId === statistics.players[1]?.id) {
            setAnimationAway180(true);
            setTimeout(() => {
                setAnimationAway180(false);
            }, 2000);
        }
        if (statistics.is180PlayerId === statistics.players[0]?.id) {
            setAnimationHome180(true);
            setTimeout(() => {
                setAnimationHome180(false);
            }, 2000);
        }
    }, [statistics]);

    useEffect(() => {
        dispatch(getScoreboardStatsInit({gameId:Number(gameId),gameType:DartsGameTypes.SHOOTOUT}))
    }, []);


    const renderFirstThrowerInLegPointer = (firstThrower: boolean) => {

        if (!firstThrower) {
            return;
        }
        return (
            <div
                className="current-thrower"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/scoreboard-widget-arrow.png)`,
                }}
            />
        );
    };

    return (
        <div className="root__container">
            {/*<LegByLegTable/>*/}
            <div className="game-scores">
                <div className="game-scores__container">
                    <div className="wrapper">
                        <div className="game-scores-table">
                            <div className="game-scores-header">
                                <div className="name-cell">
                                    <div>9 Dart Shootout</div>
                                </div>
                                <div className="game-scores-header-right-wrapper">
                                    <div className="game-scores-right-score">
                                        <div>Score</div>
                                    </div>
                                </div>

                            </div>
                            <div className="game-scores-body">
                                {statistics.players?.map((player, index) => {
                                    return (
                                        <div className="game-scores-row" key={player.id}>
                                            <div className="game-scores-row__player">
                                                <div className="player__container">
                                                    <div className="player__info">
                                                        <div
                                                            className="player__throws"
                                                            style={setCurrentThrowerStyles(player.id === statistics.nextPlayerId)}
                                                        >
                                                            {statistics.numThrows[index]}
                                                        </div>
                                                        <div className="player__name">
                                                            {player.name}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {renderFirstThrowerInLegPointer(player.id === statistics.firstPlayerId)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="game-scores-body-scores-wrapper">
                                                <div className="score score-value">
                                                    <div>{statistics.score[index]}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <GameScoreFooter/>
                    </div>
                    <div
                        className={
                            animationAway180
                                ? "animation-180-away-active"
                                : "animation-180-away"
                        }
                    >
                        <p>180</p>
                    </div>
                    <div
                        className={
                            animationHome180
                                ? "animation-180-home-active"
                                : "animation-180-home"
                        }
                    >
                        <p>180</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShootOutGameScores;
