import React, {ChangeEvent, useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Button,
    Form,
    Input, Label
} from 'semantic-ui-react'
import '../style.scss';
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    createNewsItem,
    editModeHandler,
    editNewsItem,
    formHandler
} from "../../../../store/cms/modusNews/cmsModusNewsSlice";
import {formDataHandlers, updateFormData} from "../helpers/formDataHandlers";
import {inputFileHandler} from "../helpers/inputFileHandler";
import {getTag, getTagId} from "../helpers/getId";
import './style.scss';

export interface IFormData {
    titleValue: string;
    subtitleValue: string;
    tagValue: number;
    dateValue: string;
    fileValue: string;
    imgValue: string;
    imgName: string;
    youtubeLink: string;
    imageId: number;
    newsId: number;

}

const MssNewsForm = () => {
    const IMG_SRC = 'data:image/jpeg;base64,';
    const [formData, setFormData] = useState<IFormData>({tagValue:1} as IFormData);
    const [textEditorValue, setTextEditorValue] = useState('');
    const dispatch = useAppDispatch();
    const {editMode, isOpen, newsItem} = useAppSelector((state) => state.cmsNews);

    useEffect(() => {
        if (editMode && newsItem.id) {
            const {title, subtitle, newsDate, text, tag, images, videoUrl} = newsItem;

            setFormData({
                ...formData,
                imgName: "Image.png",
                titleValue: title,
                subtitleValue: subtitle,
                tagValue: tag,
                dateValue: newsDate?.substring(0, 10),
                imgValue: `${IMG_SRC}${images[0]?.image}`,
                newsId: images[0].newsId,
                imageId: images[0].id,
                youtubeLink: videoUrl ? videoUrl! : ""

            })
            setTextEditorValue(text);

        }
    }, [newsItem])


    const formVisibilityHandler = () => {
        dispatch(formHandler(false));
        dispatch(editModeHandler(false));
    }

    const createFormHandler = () => {
        const bodyFormData = formDataHandlers(formData, textEditorValue)
        if (textEditorValue.length === 0) {
            return alert('The "News body" field cannot be empty')
        }
        dispatch(createNewsItem(bodyFormData))
    }

    const updateFormHandler = () => {
        const editNewsItemPayload = updateFormData(formData, textEditorValue)
        const body = {
            payload:editNewsItemPayload,
            id:newsItem.id
        }
        dispatch(editNewsItem(body))
    }


    const renderPreFilledForm = () => {
        return (
            <Form onSubmit={updateFormHandler} encType="multipart/form-data">
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Headline'/>
                    <Form.Field
                        className='mss-input'
                        placeholder='news item header'
                        control={Input}
                        type='text'
                        value={formData.titleValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            titleValue: e.target.value
                        })}
                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Subtitle*'/>
                    <Form.Field
                        required
                        className='mss-input'
                        placeholder='news series/week subtitle'
                        control={Input}
                        type='text'
                        value={formData.subtitleValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            subtitleValue: e.target.value
                        })}

                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Date'/>
                    <Form.Field
                        className='mss-input mss-date'
                        control={Input}
                        type='date'
                        value={formData.dateValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            dateValue: e.target.value
                        })}
                    />

                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Tag'/>
                    <select
                        className='mss-select'
                        id="tag"
                        value={getTag(formData.tagValue)}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setFormData({
                                ...formData,
                                tagValue: getTagId(e.target.value)
                            })
                        }
                        }

                    >
                        <option value="Blog">Blog</option>
                        <option value="Announcement">Announcement</option>
                        <option value="PressRelease">Press Release</option>
                    </select>
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Youtube'/>
                    <Form.Field
                        className='mss-input'
                        placeholder='skip or insert link'
                        control={Input}
                        type='text'
                        value={formData.youtubeLink}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            youtubeLink: e.target.value
                        })}
                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Image'/>
                    <input
                        id='img'
                        type="file"
                        onChange={(e) => inputFileHandler({
                            e,
                            formData,
                            setFormData
                        })}/>
                </div>
                <div className="mss-image">
                    <img src={formData.imgValue} alt=""/>
                </div>

                <Label className='mss-label mss-label-textarea' content='Body*'/>
                <div style={{height: '50%'}}>
                    <ReactQuill
                        className='mms-text-editor'
                        theme="snow"
                        value={textEditorValue}
                        onChange={setTextEditorValue}/>
                </div>
                <Form.Field
                    className='mss-submit'
                    color="green"
                    control={Button}
                    type='submit'
                    content='Update news item'/>
            </Form>
        );
    }

    const getEmptyForm = () => {
        return (
            <Form onSubmit={createFormHandler} encType="multipart/form-data">
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Headline*'/>
                    <Form.Field
                        required
                        name='title'
                        className='mss-input'
                        placeholder='news item header'
                        control={Input}
                        type='text'
                        value={formData.titleValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            titleValue: e.target.value
                        })}

                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Subtitle*'/>
                    <Form.Field
                        required
                        className='mss-input'
                        placeholder='news series/week subtitle'
                        control={Input}
                        type='text'
                        value={formData.subtitleValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            subtitleValue: e.target.value
                        })}

                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Date*'/>
                    <Form.Field
                        required
                        className='mss-input'
                        control={Input}
                        type='date'
                        value={formData.dateValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            dateValue: e.target.value
                        })}
                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Tag*'/>
                    <select
                        className='mss-select'
                        id="tag"
                        onChange={(e) => setFormData({...formData, tagValue: getTagId(e.target.value)})}

                    >
                        <option value="Blog">Blog</option>
                        <option value="Announcement">Announcement</option>
                        <option value="PressRelease">Press Release</option>
                    </select>
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Youtube'/>
                    <Form.Field
                        required
                        name='youtube'
                        className='mss-input'
                        placeholder='skip or insert link'
                        control={Input}
                        type='text'
                        value={formData.youtubeLink}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                            ...formData,
                            youtubeLink: e.target.value
                        })}
                    />
                </div>
                <div className="mss-label-control-wrapper">
                    <Label className='mss-label' content='Image*'/>
                    <input
                        required
                        id='img'
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => inputFileHandler({e, formData, setFormData})}/>
                </div>
                <div className="mss-image">
                    <img src={formData.imgValue} alt=""/>
                </div>
                <Label className='mss-label mss-label-textarea' content='Body*'/>
                <div style={{height: '50%'}}>
                    <ReactQuill
                        className='mms-text-editor'
                        theme="snow"
                        value={textEditorValue}
                        onChange={setTextEditorValue}/>
                </div>
                <Form.Field
                    className='mss-submit'
                    type='submit'
                    color="green"
                    control={Button}
                    content='Create news item'/>
            </Form>
        );
    }

    return (
        <div className={'mss-form'}>
            <div className="close-container" onClick={formVisibilityHandler}>
                <div className="leftright"></div>
                <div className="rightleft"></div>
            </div>
            <div>
                {isOpen && getEmptyForm()}
                {editMode && renderPreFilledForm()}
            </div>
        </div>
    );
}

export default MssNewsForm;
