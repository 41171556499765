import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ILiveScore,
    ILiveScoreInitPayload,
    ILiveScoreLocations,
    ILiveScoreReducer,
    ILiveScoreSidebar, LiveScoreHeader,
    LiveScoreRootObject, LiveScoreStatistics, LiveScoreTimelines, LiveScoreTurnShots,
    OnEndGameLiveScore,
    OnShotForLiveScore,
    ShotUpdateSocketData
} from "./types";
import {API_LIVE_SCORE_INIT, API_LIVE_SCORE_SIDEBAR} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {formatSideBar} from "./helpers/formatSideBar";
import {
    disconnectFromGameSocket,
    joinDartsOnSpecifiedDay,
    joinGameAndGetPermission
} from "../../../services/connection.service";


const initialState: ILiveScoreReducer = {
    allInformation: {
        liveScore: {
            header: {
                legNum: 0,
                legs: [],
                scores: [],
                players: [
                    {
                        id: 0,
                        name: ""
                    },
                    {
                        id: 0,
                        name: ""
                    }
                ],
                nextPlayerId: 0,
                status: 2,
                turnShots: {} as LiveScoreTurnShots
            },
            timelines: {} as LiveScoreTimelines,
            statistics: {
                statistics: [],
                players: [],
                groups: []
            }
        },
        sidebar: {} as ILiveScoreSidebar,
        sidebarEvents: {},
        gameSrartDate: ''
    },
    lastShot: {} as OnShotForLiveScore,
    liveScoreLoader: false,
    gameInfo: {},
    activeSideBarEvent: 0
}

export const getLiveScoreInfo = createAsyncThunk<LiveScoreRootObject, ILiveScoreInitPayload, { rejectValue: string }>(
    'liveScoreInfo/schedule',
    async (payload, {rejectWithValue}) => {
        const {gameId, gameType} = payload;
        try {
            await joinGameAndGetPermission(payload.gameId)
            const {data} = await client.get(`${API_LIVE_SCORE_INIT}/${gameType}/${gameId}`);
            return data;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }

    }
)

export const changeGameLiveScore = createAsyncThunk<LiveScoreRootObject, ILiveScoreInitPayload, { rejectValue: string }>(
    'liveScoreInfo/changeGame',
    async (payload, {rejectWithValue}) => {
        const {gameId, gameIdInSocket} = payload;
        try {
            if (gameIdInSocket !== gameId) {
                await disconnectFromGameSocket(gameIdInSocket);
            }
            await joinGameAndGetPermission(gameId);
            const {data} = await client.get(`${API_LIVE_SCORE_INIT}/${payload.gameType?.toLocaleLowerCase()}/${payload.gameId}`);
            return data;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message);
            return rejectWithValue(e.response.data);
        }

    }
)

export const updateSidebar = createAsyncThunk<ILiveScoreLocations[], string, { rejectValue: string }>(
    'liveScoreInfo/updateSideBar',
    async (date, {rejectWithValue}) => {
        try {
            await joinDartsOnSpecifiedDay(date);
            const {data} = await client.get(`${API_LIVE_SCORE_SIDEBAR}/${date}`);
            return data.locations;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message);
            return rejectWithValue(e.response.data);
        }
    }
)
export const liveScoreInfoSlice = createSlice({
    name: 'liveScoreInfo',
    reducers: {
        setLastShot: (state, action: PayloadAction<ShotUpdateSocketData>) => {
            const {bed, ring, message} = action.payload;
            state.lastShot = {bed, ring, message};
            // state.allInformation.liveScore.turnShotsHomeAway = turnShotsHomeAway;
            // state.allInformation.liveScore.turnShots = turnShots;

        },
        setLiveScoreHeader: (state, action: PayloadAction<LiveScoreHeader>) => {
            state.allInformation.liveScore.header = action.payload

        },

        setLiveScoreTimelines: (state, action: PayloadAction<LiveScoreTimelines>) => {
            state.allInformation.liveScore.timelines = action.payload

        },

        setLiveScoreStatistic: (state, action: PayloadAction<LiveScoreStatistics>) => {
            state.allInformation.liveScore.statistics = action.payload

        },
        updateDataWithSocket: (state, action: PayloadAction<ILiveScore>) => {
            // const { playerIDs, legs, timeline, statistics, scores, status } = action.payload;
            // state.allInformation.liveScore.playerIDs = playerIDs;
            // state.allInformation.liveScore.legs = legs;
            // state.allInformation.liveScore.timeline = timeline;
            // state.allInformation.liveScore.statistics = statistics;
            // state.allInformation.liveScore.scores = scores;
            // state.allInformation.liveScore.status = status;
        },

        setEndGame: (state, action: PayloadAction<OnEndGameLiveScore>) => {
            // const { legs, scores, status } = action.payload;
            // state.allInformation.liveScore.legs = legs;
            // state.allInformation.liveScore.scores = scores;
            // state.allInformation.liveScore.status = status;
        },
        setSidebar: (state, action: PayloadAction<ILiveScoreLocations[]>) => {
            state.allInformation.sidebarEvents = formatSideBar(action.payload);
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLiveScoreInfo.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(getLiveScoreInfo.fulfilled, (state: ILiveScoreReducer, action) => {
                state.allInformation.liveScore = {
                    header: action.payload.header,
                    statistics: action.payload.statistics,
                    timelines: action.payload.timelines
                };
                state.allInformation.sidebar = action.payload.sidebar;
                state.activeSideBarEvent = action.meta.arg.gameId;
                state.liveScoreLoader = false;
                if (action.payload.sidebar) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload.sidebar.locations);
                }
            })
            .addCase(getLiveScoreInfo.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(changeGameLiveScore.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(changeGameLiveScore.fulfilled, (state: ILiveScoreReducer, action) => {
                state.liveScoreLoader = false;
                state.allInformation.liveScore = {
                    header: action.payload.header,
                    statistics: action.payload.statistics,
                    timelines: action.payload.timelines
                };
                state.activeSideBarEvent = action.meta.arg.gameId;
                state.liveScoreLoader = false;
            })
            .addCase(changeGameLiveScore.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(updateSidebar.fulfilled, (state: ILiveScoreReducer, action) => {
                if (action.payload) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload);
                }
            })
    }
})

export const {
    setLastShot,
    updateDataWithSocket,
    setEndGame,
    setSidebar,
    setLiveScoreHeader,
    setLiveScoreTimelines,
    setLiveScoreStatistic
} = liveScoreInfoSlice.actions;
export default liveScoreInfoSlice.reducer;
