import React, {useEffect} from 'react';
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import mmsLogo from "../../img/MSSLogoMain.png"
import {getPostMatchStats} from "../../store/pages/postMatch/postMatchSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import './styles.scss';
import './styles.media.scss';


const PostMatchPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const {playersStatistics, isLoading} = useAppSelector((state) => state.postMatch);


    useEffect(() => {
        dispatch(getPostMatchStats())
    }, [dispatch]);

    const {players,statistics} = playersStatistics

    return (

        <div className="post-match-wrapper">
            <div className="static-statistic">
                {
                    isLoading ? <OverlayLoader/> :
                        <div className="static-statistic-wrapper">
                            <div className="static-statistic-names">

                                {
                                    players &&
                                    <div>
                                    <span
                                        className="static-statistic-names-firstname">
                                        {players[0].name}
                                    </span>
                                        {/*{players && ` ${getPlayerName(players?.home)[1]}`}*/}
                                    </div>
                                }

                                <div className="static-statistic-total-points">
                                    <div className="static-statistic-total-points-wrapper">{statistics[0]?.totalScore}</div>
                                    <div className="static-statistic-total-points-wrapper">{statistics[1]?.totalScore}</div>
                                </div>

                                {
                                    players &&
                                    <div>
                                    <span
                                        className="static-statistic-names-firstname">{players[1].name}</span>
                                        {/*{players && ` ${getPlayerName(players?.away)[1]}`}*/}
                                    </div>
                                }
                            </div>
                            <div className="static-statistic-container">
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {statistics[0]?.average?.toFixed(2)}
                                    </div>
                                    <div className="static-statistic-item-title"> 3 dart average</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {statistics[1]?.average.toFixed(2)}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {statistics[0]?.turns180}
                                    </div>
                                    <div className="static-statistic-item-title">180s</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {statistics[1]?.turns180}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {statistics[0]?.turns140to179}
                                    </div>
                                    <div className="static-statistic-item-title">140+</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {statistics[1]?.turns140to179}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {statistics[0]?.checkouts}
                                    </div>
                                    <div className="static-statistic-item-title">Checkouts</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {statistics[1]?.checkouts}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value ">
                                        {`${statistics[0]?.checkoutPercentage}%`}
                                    </div>
                                    <div className="static-statistic-item-title">Checkout %</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {`${statistics[1]?.checkoutPercentage}%`}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {statistics[0]?.highestCheckout}
                                    </div>
                                    <div className="static-statistic-item-title">Highest checkout</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {statistics[1]?.highestCheckout}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {statistics[0]?.checkouts100plus}
                                    </div>
                                    <div className="static-statistic-item-title">100+ checkouts</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {statistics[1]?.checkouts100plus}
                                    </div>
                                </div>
                                <div className="static-statistic-item">
                                    <div className="static-statistic-logo">
                                        <img src={mmsLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default PostMatchPage;
