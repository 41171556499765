import * as signalR from "@microsoft/signalr";
import {LS_TOKEN} from "./constants/localStorageItems";
import {isConnected, WEBSOCKET_CONNECT_TYPE} from "./store/webSockets/webSocketsSlice";
import {
    setEndGame,
    setLastShot, setLiveScoreHeader, setLiveScoreStatistic, setLiveScoreTimelines,
    setSidebar,
    updateDataWithSocket
} from "./store/pages/liveScoreInfo/liveScoreInfoSlice";
import {
    ILiveScore,
    ILiveScoreSidebar, LiveScoreHeader, LiveScoreStatistics, LiveScoreTimelines,
    OnEndGameLiveScore,
    ShotUpdateSocketData
} from "./store/pages/liveScoreInfo/types";
import {getPreMatchStats} from "./store/pages/preMatch/preMatchSlice";
import {
    deleteActiveGameAndTournament,
    setCsvStatisticsFinished,
    setDartsInitData, setDownloadedGames
} from "./store/pages/schedule/scheduleSlice";
import {IActiveTournamentGameData} from "./store/pages/activeGameScore/toolkit/types";
import {
    Averages,
    BestOfXLegs, Checkouts,
    LegByLeg, Rounds
} from "./store/pages/scoreboardAndStats/types";

import {shootOutSetGameData} from "./store/pages/shootOutActiveGameScore/shootOutActiveGameScoreSlice";
import {setLeagueTable} from "./store/pages/leagueTable/leagueTableSlice";
import {IDartsInitData} from "./store/pages/schedule/types";
import {setActiveGameScoreData} from "./store/pages/activeGameScore/toolkit/activeGameScoreSlice";
import {
    setDoubleMissed, setTurn180BySocket,
    updateAveragesBySocket,
    updateBestOf7LegsBySocket,
    updateCheckoutBySocket,
    updateLegByLegBySocket, updatePlayersInfoBySocket,
    updateRoundsBySocket
} from "./store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import {IGetDailyResultResponse, updateResultBySocket} from "./store/dailyResults/dailyResultSlice";

const getConnection = (token: string): signalR.HubConnection => {

    return new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_ENDPOINT}/api/interactions`,
            {
                accessTokenFactory: () => token,
            })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
};

const createSocketMiddleware = () => {
    let hubConnection: signalR.HubConnection;
    let userId = -1;
    return (storeAPI: any) => (next: any) => (action: any) => {
        switch (action.type) {
            case WEBSOCKET_CONNECT_TYPE: {
                userId = action.payload;
                const token = localStorage.getItem(LS_TOKEN);
                if (!token) {
                    console.error('Token is empty');
                    return next(action);
                }
                hubConnection = getConnection(token);
                hubConnection.start().then(() => {
                    console.log("Connected");
                    storeAPI.dispatch(isConnected(true));
                });
                hubConnection.onclose((error: any = '') => {
                    console.log("Connection closed. " + error);
                    storeAPI.dispatch(isConnected(false));
                });

                hubConnection.on('DartsLiveScoreBoard', (data) => {
                    const resp: ShotUpdateSocketData = JSON.parse(data);
                    storeAPI.dispatch(setLastShot(resp));
                });

                hubConnection.on('DartsLiveScoreSideBar', (data) => {
                    const sidebar: ILiveScoreSidebar = JSON.parse(data);
                    storeAPI.dispatch(setSidebar(sidebar.locations))
                });

                hubConnection.on('onUpdateLifeScore', (data) => {
                    const resp: ILiveScore = JSON.parse(data);
                    storeAPI.dispatch(updateDataWithSocket(resp))
                });

                hubConnection.on('onEndGameLiveScore', (data) => {
                    const resp: OnEndGameLiveScore = JSON.parse(data);
                    storeAPI.dispatch(setEndGame(resp))

                });

                hubConnection.on('onGameEnd', () => {
                    const url = window.location.href;
                    const penultimateSlashIndex = url.split('/');
                    const currentPageUrl = penultimateSlashIndex[penultimateSlashIndex.length - 2]

                    if (currentPageUrl === 'pre-match-animation' || currentPageUrl === 'pre-match-stats') {
                        storeAPI.dispatch(getPreMatchStats());
                    }
                    storeAPI.dispatch(deleteActiveGameAndTournament());
                    if (currentPageUrl === 'darts') {
                        window.location.href = '/schedule'
                    }
                });
                hubConnection.on('onActiveGameScore', (data) => {
                    const resp: IActiveTournamentGameData = JSON.parse(data);
                    storeAPI.dispatch(setActiveGameScoreData(resp))
                });
                hubConnection.on('DartsStatsDoubleMissed', (data) => {
                    const resp: IActiveTournamentGameData = JSON.parse(data);
                    storeAPI.dispatch(setDoubleMissed(resp))
                });
                hubConnection.on('onActiveGameScore', (data) => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(shootOutSetGameData(resp))
                });

                hubConnection.on('DartsStatsLeagueTable', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(setLeagueTable(resp))
                });

                hubConnection.on('StatisticsCSV', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(setDownloadedGames(resp));
                });
                hubConnection.on('StatisticsCSVCompleted', data => {
                    storeAPI.dispatch(setCsvStatisticsFinished(null));
                });
                hubConnection.on('onDartsInit', data => {
                    const resp: IDartsInitData = JSON.parse(data);
                    storeAPI.dispatch(setDartsInitData(resp))
                });

                hubConnection.on('DartsLiveScoreHeader', data => {
                    const resp: LiveScoreHeader = JSON.parse(data);
                    storeAPI.dispatch(setLiveScoreHeader(resp))
                });
                hubConnection.on('DartsLiveScoreTimelines', data => {
                    const resp: LiveScoreTimelines = JSON.parse(data);
                    storeAPI.dispatch(setLiveScoreTimelines(resp))
                });
                hubConnection.on('DartsLiveScoreStatistics', data => {
                    const resp: LiveScoreStatistics = JSON.parse(data);
                    storeAPI.dispatch(setLiveScoreStatistic(resp))
                });

                hubConnection.on('DartsStatsBestOfXLegs', data => {
                    const resp: BestOfXLegs = JSON.parse(data);
                    storeAPI.dispatch(updateBestOf7LegsBySocket(resp))
                });

                hubConnection.on('DartsStatsPlayers', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(updatePlayersInfoBySocket(resp))
                });

                hubConnection.on('DartsStatsAverages', data => {
                    const resp: Averages = JSON.parse(data);
                    storeAPI.dispatch(updateAveragesBySocket(resp))
                });
                hubConnection.on('DartsStatsCheckouts', data => {
                    const resp: Checkouts = JSON.parse(data);
                    storeAPI.dispatch(updateCheckoutBySocket(resp))
                });

                hubConnection.on('DartsStatsLegByLeg', data => {
                    const resp: LegByLeg = JSON.parse(data);
                    storeAPI.dispatch(updateLegByLegBySocket(resp))
                });

                hubConnection.on('DartsStatsRounds', data => {
                    const resp: Rounds = JSON.parse(data);
                    storeAPI.dispatch(updateRoundsBySocket(resp))
                });

                hubConnection.on('DartsStatsTurns180', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(setTurn180BySocket(resp))
                });

                hubConnection.on('DartsDailyResults', data => {
                    const resp: IGetDailyResultResponse = JSON.parse(data);
                    storeAPI.dispatch(updateResultBySocket(resp))
                });

                return next(action);
            }

            default: {
                return next(action)
            }
        }
    }
};

export {createSocketMiddleware}
