import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {
    IDartsShootOutGames,
    IDartsShootOutGamesReducer,
    IGetShootoutGamesResponse,
    IShootOutGameEditPayload,
    IShootOutGamePayload
} from "./types";
import {API_DARTS_GAMES_INIT_DATA, API_DARTS_SHOOTOUT_GAMES} from "../../../../constants";
import {toastr} from "react-redux-toastr";
import {IGamesInitData} from "../games/types";

const initialState: IDartsShootOutGamesReducer = {
    shootOutGames: [] as IDartsShootOutGames[],
    initData: {} as IGamesInitData,
    editGameId: 0,
    filteredGames: [],
    editMode: false,
    totalItems: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
}

export const getShootOutDartsInit = createAsyncThunk<IGamesInitData>(
    'cmsShootOutDartsGames/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_DARTS_GAMES_INIT_DATA);
            return data;
        } catch (error) {
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Shootout darts games init: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getDartsShootOutGames = createAsyncThunk<IGetShootoutGamesResponse,string>(
    'getDartsShootOutGames/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_SHOOTOUT_GAMES}?${query}`);
            return data;
        } catch (error) {
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Shootout darts games init: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const createDartsShootOutGame = createAsyncThunk<IDartsShootOutGames, IShootOutGamePayload>(
    'getDartsShootOutGames/post',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_DARTS_SHOOTOUT_GAMES, payload);
            toastr.success("CMS", "Shoot out handler has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a shoot out handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Shoot out darts games creating: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const editDartsShootOutGame = createAsyncThunk<IDartsShootOutGames, IShootOutGameEditPayload>(
    'cmsShootOutDartsGames/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_SHOOTOUT_GAMES}`, payload);
            toastr.success("CMS", "Shoot out handler has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a shoot out handler.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Adc darts games editing: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteShootOutGame = createAsyncThunk<IDartsShootOutGames, number>(
    'cmsShootOutDartsGames/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_SHOOTOUT_GAMES}/${id}`);
            toastr.success("", "Shoot out handler has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a shoot out handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Shoot out darts game deleting: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const cmsShootOutDartsGamesSlice = createSlice({
    name: 'cmsShootOutDartsGames',
    reducers: {
        setShootOutGameEditMode: (state, action:PayloadAction<boolean>) => {
            state.editMode = action.payload
        },
        setPageNumber :(state, action) => {
            state.pageNum = action.payload
        },
        setEditGameId:(state,action) => {
            state.editGameId = action.payload
            state.editMode = true
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsShootOutGames.fulfilled, (state: IDartsShootOutGamesReducer, action) => {
                state.shootOutGames = action.payload.items
                state.totalPages = action.payload.totalPages
            })
            .addCase(getShootOutDartsInit.fulfilled, (state: IDartsShootOutGamesReducer, action) => {
                state.initData = action.payload;
            })
            .addCase(deleteShootOutGame.fulfilled, (state: IDartsShootOutGamesReducer, action) => {
                state.shootOutGames = state.shootOutGames.filter(
                    (game) => game.id !== action.meta.arg
                );
            })
            .addCase(createDartsShootOutGame.fulfilled, (state: IDartsShootOutGamesReducer, action) => {
                state.shootOutGames = state.shootOutGames.concat(action.payload);
            })

            .addCase(editDartsShootOutGame.fulfilled, (state: IDartsShootOutGamesReducer, action) => {
                state.shootOutGames = state.shootOutGames.map((game) => {
                    if (game.id === action.payload.id) {
                        game = action.payload
                    }
                    return game
                })
            })
    },
});

export const { setShootOutGameEditMode,setPageNumber,setEditGameId } = cmsShootOutDartsGamesSlice.actions
export default cmsShootOutDartsGamesSlice.reducer;

