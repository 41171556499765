import {Icon, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import {
    deleteNewsItem,
    editModeHandler,
    getSingleNewsItem,
    setNewsItemId
} from "../../../../store/cms/modusNews/cmsModusNewsSlice";
import React from "react";
import {AppDispatch} from "../../../../store";

interface IGetControlButtons {
    id: number;
    date: string;
    isOpen: boolean;
    isLoading: boolean;
    editMode: boolean;
    dispatch: AppDispatch;
}

export const renderControlButtons = (
    {
        id,
        date,
        isOpen, isLoading,
        editMode,
        dispatch
    }: IGetControlButtons): React.JSX.Element => {

    const editNewsItemHandler = (id: number): void => {
        dispatch(editModeHandler(true));
        dispatch(setNewsItemId(id));
        dispatch(getSingleNewsItem(id));
    }

    return (
        <Table.Cell className='mss-btn-wrapper'>
            <Icon
                onClick={() => editNewsItemHandler(id)}
                disabled={isOpen || isLoading || editMode}
                name='pencil alternate'
                link
                color="grey"
                size="small"
            />
            {isOpen || isLoading || editMode ?
                <Icon
                    name='trash alternate outline'
                    link
                    size="small"
                    disabled={true}
                    color='red'

                   />
                :
                <DartsDeleteModal
                    id={id}
                    deleteAction={(id: number) => dispatch(deleteNewsItem(id))}
                    confirmText={`Are you sure?`}
                    titleModal={`Delete a news item from ${date}`}
                />
            }
        </Table.Cell>
    )
};
