import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../hooks/hooks";
import {renderFirstThrowerInLegPointer} from "./helpers/renderFirstThrowerInLegPointer";
import {setCurrentThrowerStyles} from "./helpers/setCurrentThrowerStyles";
import GameScoreFooter from "./components/GameScoreFooter";
import LegByLegTable from "../../../DartMatchStatsTable/components/LegByLegTable/LegByLegTable";
import "./styles.scss";
import Series180 from "../Series180/Series180";

const GameScores: React.FC = React.memo(() => {
    const {scoreBoardAndStatsInfo: {bestOfXLegs,turns180}} = useAppSelector((state) => state.scoreboardAndStats);
    const [animationAway180, setAnimationAway180] = useState(false);
    const [animationHome180, setAnimationHome180] = useState(false);
    const statistics = bestOfXLegs

    useEffect(() => {
        if (statistics.is180PlayerId === statistics.players[1].id) {
            setAnimationAway180(true);
            setTimeout(() => {
                setAnimationAway180(false);
            }, 2000);
        }
        if (statistics.is180PlayerId === statistics.players[0].id) {
            setAnimationHome180(true);
            setTimeout(() => {
                setAnimationHome180(false);
            }, 2000);
        }
    }, [statistics]);

    return (
        <div className="root__container">
            <div className="game-scores">
                <div className="game-scores__container">
                    <div className="wrapper">
                        <div className="game-scores-table">
                            <div className="game-scores-header">
                                <div className="name-cell">
                                    <div>{statistics.title}</div>
                                </div>
                                <div className="name-cell"></div>
                                <div className=" name-cell-widget-name">
                                    <div>
                                        <div>Best of {(statistics?.legsToWin * 2) - 1} legs</div>
                                    </div>
                                </div>
                                <div className="game-scores-header-right-wrapper">
                                    <div className="value-column">
                                        <div>Legs</div>
                                    </div>
                                    <div className="game-scores-right-score">
                                        <div>Score</div>
                                    </div>
                                </div>

                            </div>
                            <div className="game-scores-body">
                                {statistics.players.map((player, index) => {
                                    return (
                                        <div className="game-scores-row">
                                            <div className="game-scores-row__player">
                                                <div className="player__container">
                                                    <div className="player__info">
                                                        <div
                                                            className="player__throws"
                                                            style={setCurrentThrowerStyles(
                                                                player.id === statistics.nextPlayerId
                                                            )
                                                            }
                                                        >
                                                            {statistics?.numThrows[index]}
                                                        </div>
                                                        <div
                                                            className="player__name"
                                                        >
                                                            {player.name}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {renderFirstThrowerInLegPointer(
                                                            player.id === statistics.firstPlayerId
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="game-scores-body-scores-wrapper">
                                                <div className="score score-legs">
                                                    <div>{statistics?.legs[index]}</div>
                                                </div>
                                                <div className="score score-value">
                                                    <div>{statistics?.score[index]}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={'game-scores-footer'}>
                                <GameScoreFooter/>
                            </div>
                        </div>
                        <div
                            className={
                                animationAway180
                                    ? "animation-180-away-active"
                                    : "animation-180-away"
                            }
                        >
                            <p>180</p>
                        </div>
                        <div
                            className={
                                animationHome180
                                    ? "animation-180-home-active"
                                    : "animation-180-home"
                            }
                        >
                            <p>180</p>
                        </div>
                    </div>
                </div>
            </div>
            <LegByLegTable/>
            <div className={"series180"}>
                <Series180 title={"Series 180"} score={turns180.series180}/>
                <Series180 title={"Week 180"} score={turns180.week180}/>
            </div>
        </div>
    );
})

export default GameScores;
