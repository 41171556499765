import React, {useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import MssNewsForm from "./MssNewsForm/MssNewsForm";
import DimmerLoader from "../../../components/DImmerLoader/DimmerLoader";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    formHandler,
    getNews,
} from "../../../store/cms/modusNews/cmsModusNewsSlice";
import {renderNewsTable} from "./helpers/renderNewsTable";
import './style.scss';

const MssNewsTable = () => {
    const dispatch = useAppDispatch();
    const {
        news,
        isOpen,
        isLoading,
        editMode,
    } = useAppSelector((state) => state.cmsNews);

    useEffect(() => {
        dispatch(getNews())
    }, [])

    const formVisibilityHandler = () => dispatch(formHandler(!isOpen));

    return (
        <div>
            <div className="mss-btn-create">
                <Button
                    disabled={isOpen || isLoading || editMode}
                    onClick={formVisibilityHandler}
                    content="Add news item"
                    color="green"/>
                {isOpen && <MssNewsForm/>}
            </div>
            {isLoading && news ? <DimmerLoader/> : renderNewsTable({
                news,
                isLoading,
                editMode,
                isOpen,
                dispatch
            })}
            {editMode && <MssNewsForm/>}
            {isOpen && <MssNewsForm/>}
        </div>
    );
}

export default MssNewsTable;
