import React from "react";
import {useState, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Form, Button, Icon, Input, Message, Label, Select} from "semantic-ui-react";
import * as yup from "yup";
import Flatpickr from "react-flatpickr";
import "./formStyle.scss";
import toolTip from '../../../img/info-tooltip.png';
import {PlayerAdcType} from "../../../enums/playerAdcTypes";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {createDartsPlayer, editDartsPlayer, playersHideForm} from "../../../store/cms/darts/players/cmsPlayersSlice";
import {DartsPlayerPayload} from "../../../store/cms/darts/players/types";

const DartsPlayersTableForm = () => {
    const dispatch = useAppDispatch();
    const {editMode, dartsPlayers, editPlayerId} = useAppSelector((state) => state.cmsPlayers);
    const [genderValue, setGenderValue] = useState("Male");
    const [throwHand, setThrowHand] = useState("Right");
    const [dateOfBirth, setDateOfBirth] = useState<Date | string>(new Date());

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGenderValue(e.target.value)
    }
    const handleChangeThrowHand = (e: React.ChangeEvent<HTMLInputElement>) => {
        setThrowHand(e.target.value);
    }

    const adcOptions = [
        {
            key: 1,
            value: PlayerAdcType.UK,
            text: 'ADC UK',
        },
        {
            key: 2,
            value: PlayerAdcType.Europe,
            text: 'ADC Europe',
        },
        {
            key: 3,
            value: PlayerAdcType.Oceania,
            text: 'ADC Oceania',
        },
    ];


    useEffect(() => {
        if (editPlayerId && editMode) {
            const updateCandidate = dartsPlayers.find(
                (player) => player.id === editPlayerId
            );
            for (let key in updateCandidate) {
                // @ts-ignore
                setValue(key, updateCandidate[key]);
            }
            setGenderValue(updateCandidate!.gender);
            setThrowHand(updateCandidate!.throwHand);
            setDateOfBirth(updateCandidate!.dateOfBirth)
        }
    }, [editPlayerId, editMode]);

    const editPlayer = () => {
        const updatedPlayer: DartsPlayerPayload = {
            id: editPlayerId!,
            name: getValues("name"),
            surname: getValues("surname"),
            nickname: getValues("nickname"),
            abbr: getValues("abbr"),
            displayname: getValues("displayname"),
            gender: genderValue,
            hometown: getValues("hometown"),
            nationality: getValues("nationality"),
            dartsUsed: getValues("dartsUsed"),
            careerBest: getValues("careerBest"),
            throwHand: throwHand,
            dateOfBirth,
            adcQualifier: getValues("adcQualifier")?getValues("adcQualifier"):null
        };
        dispatch(editDartsPlayer(updatedPlayer));
        reset();
    };
    let validationSchema = yup.object().shape({
        name: yup.string().required(),
        surname: yup.string().required(),
        nickname: yup.string(),
        abbr: yup.string().max(3),
        displayname: yup.string().max(24),
        hometown: yup.string().required().min(2),
        nationality: yup.string().required().min(2),
        dartsUsed: yup.string().required().min(2),
        careerBest: yup.string().required().min(2),
    });

    const {control, errors, handleSubmit, getValues, setValue, reset, watch} =
        useForm({
            mode: "onChange",
            resolver: yupResolver(validationSchema),
        });

    const displayName = `${watch("name")} ${watch("surname")}`;
    useEffect(() => {
        if (displayName !== "undefined undefined" && !editMode) {
            setValue("displayname", displayName.slice(0, 24));
        } else setValue("displayname", getValues('displayname'))
    }, [displayName]);

    const submitPlayer = () => {
        const newPlayer: DartsPlayerPayload = {
            name: getValues("name"),
            surname: getValues("surname"),
            nickname: getValues("nickname"),
            abbr: getValues("abbr"),
            gender: genderValue,
            displayname: getValues("displayname"),
            hometown: getValues("hometown"),
            nationality: getValues("nationality"),
            dartsUsed: getValues("dartsUsed"),
            careerBest: getValues("careerBest"),
            throwHand: throwHand,
            dateOfBirth,
            adcQualifier: getValues("adcQualifier")
        };
        dispatch(createDartsPlayer(newPlayer));
        reset();
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    return (
        <div className="form-overlay">
            <Form
                className="players-form"
                onSubmit={
                    !editMode
                        ? handleSubmit(submitPlayer)
                        : handleSubmit(editPlayer)
                }
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    style={{float: "right", cursor: "pointer"}}
                    onClick={() => dispatch(playersHideForm())}
                />
                <div className={"form-row"}>
                    <Form.Field>
                        <Label className='players-form-label'>Name</Label>
                        <Controller
                            as={Input}
                            error={errors.name}
                            name="name"
                            id="name"
                            defaultValue=""
                            control={control}
                            placeholder="enter name"
                        />
                        {errors.name && <Message negative>{errors.name.message}</Message>}
                    </Form.Field>
                    <Form.Field>
                        <Label className='players-form-label'>Surname</Label>
                        <Controller
                            as={Input}
                            error={errors.surname}
                            name="surname"
                            id="surname"
                            defaultValue=""
                            control={control}
                            placeholder="enter surname"
                        />
                        {errors.surname && (
                            <Message negative>{errors.surname.message}</Message>
                        )}
                    </Form.Field>
                    <Form.Field>
                        <Label className='players-form-label'>Nickname</Label>
                        <Controller
                            as={Input}
                            error={errors.nickName}
                            name="nickname"
                            id="nickname"
                            defaultValue=""
                            control={control}
                            placeholder="enter nickname"
                        />
                        {errors.nickName && (
                            <Message negative>{errors.nickName.message}</Message>
                        )}
                    </Form.Field>
                </div>
                <div className={"form-row"}>
                    <Form.Field className={"display-name"}>
                        <div className="players-form-tooltip-wrapper">
                            <Label className='players-form-label'>Display name</Label>

                            <div className="tooltip">
                                <img
                                    className={'players-form-tooltip-icon'}
                                    src={toolTip}
                                    alt={'tooltip-icon'}
                                />
                                <span>Players first and second names should be separated by a space. For any first name or second name that has multiple words, each word should be separated by an underscore (_). For example: Gian van Veen should be entered as Gian van_Veen, or Jimmy Floyd Hasselbaink should be entered as Jimmy_Floyd Hasselbaink. This will display their name without the underscore, but will correctly separate their first and last names for display purposes.</span>
                            </div>
                        </div>
                        <Controller
                            as={Input}
                            maxLength="24"
                            error={errors.displayname}
                            name="displayname"
                            id="displayname"
                            control={control}
                            placeholder="enter displayName (character limits - 24)"
                        />
                        {errors.displayname && (
                            <Message negative>
                                {"Value ​​greater than 16 characters Please fill in correctly"}
                            </Message>
                        )}
                    </Form.Field>
                    <Form.Field className={"abbr"}>
                        <Label className='players-form-label'>Abbr</Label>
                        <Controller
                            as={Input}
                            error={errors.abbr}
                            maxLength="3"
                            name="abbr"
                            id="abbr"
                            defaultValue=""
                            control={control}
                            placeholder="enter abbreviation (character limits - 3)"
                        />
                        {errors.abbr && <Message negative>{errors.abbr.message}</Message>}
                    </Form.Field>
                </div>
                <div className="radio-input-inner">
                    <Form.Field>
                        <Label className='players-form-label'>Male</Label>
                        <input
                            className="player-form-input-radio"
                            name="radioGroup"
                            type="radio"
                            value="Male"
                            checked={genderValue === "Male"}
                            //@ts-ignore
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Label className='players-form-label'>Female</Label>
                        <input
                            className="player-form-input-radio"
                            name="radioGroup"
                            type="radio"
                            value="Female"
                            checked={genderValue === "Female"}
                            //@ts-ignore
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Label className='players-form-label'>Other</Label>
                        <input
                            className="player-form-input-radio"
                            name="radioGroup"
                            type="radio"
                            checked={genderValue === "Other"}
                            value="Other"
                            //@ts-ignore
                            onChange={handleChange}
                        />
                    </Form.Field>
                </div>
                <div className={"form-row"}>
                    <Form.Field className={"date-of-birth"}>
                        <Label className='players-form-label'>Date of Birth:</Label>
                        <Flatpickr
                            value={dateOfBirth}
                            options={dayPickerOptions}
                            onChange={(date: Date, dayStr: string) => setDateOfBirth(dayStr)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Label className='players-form-label'>Hometown</Label>
                        <Controller
                            as={Input}
                            error={errors.hometown}
                            name="hometown"
                            id="hometown"
                            defaultValue=""
                            control={control}
                            placeholder="enter hometown"
                        />
                        {errors.hometown && (
                            <Message negative>{errors.hometown.message}</Message>
                        )}
                    </Form.Field>
                    <Form.Field>
                        <Label className='players-form-label'>Nationality</Label>
                        <Controller
                            as={Input}
                            error={errors.nationality}
                            name="nationality"
                            id="nationality"
                            defaultValue=""
                            control={control}
                            placeholder="enter nationality"
                        />
                        {errors.nationality && (
                            <Message negative>{errors.nationality.message}</Message>
                        )}
                    </Form.Field>
                </div>
                <div className={"form-row"}>
                    <Form.Field className={"dart-used"}>
                        <Label className='players-form-label'>Darts Used</Label>
                        <Controller
                            as={Input}
                            error={errors.dartsUsed}
                            name="dartsUsed"
                            id="dartsUsed"
                            defaultValue=""
                            control={control}
                            placeholder="enter dartsUsed"
                        />
                        {errors.dartsUsed && (
                            <Message negative>{errors.dartsUsed.message}</Message>
                        )}
                    </Form.Field>
                    <div className={"throw-hand"}>
                        <Label className='players-form-label throw-hand-label'>Throw hand:</Label>
                        <div className="throw-input-inner">
                            <Form.Field>
                                <Label className='players-form-label'>Right</Label>
                                <input
                                    className="player-form-input-radio"
                                    name="Right"
                                    type="radio"
                                    value="Right"
                                    checked={throwHand === "Right"}
                                    //@ts-ignore
                                    onChange={handleChangeThrowHand}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Label className='players-form-label'>Left</Label>
                                <input
                                    className="player-form-input-radio"
                                    name="Left"
                                    type="radio"
                                    value="Left"
                                    checked={throwHand === "Left"}
                                    //@ts-ignore
                                    onChange={handleChangeThrowHand}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Label className='players-form-label'>Both</Label>
                                <input
                                    className="player-form-input-radio"
                                    name="Both"
                                    type="radio"
                                    value="Both"
                                    checked={throwHand === "Both"}
                                    onChange={handleChangeThrowHand}
                                />
                            </Form.Field>
                        </div>
                    </div>
                </div>
                <div className={"form-row"}>
                    <Form.Field className={"career-best"}>
                        <Label className='players-form-label'>Career Best</Label>
                        <Controller
                            as={Input}
                            error={errors.careerBest}
                            name="careerBest"
                            id="careerBest"
                            defaultValue=""
                            control={control}
                            placeholder="enter career best"
                        />
                        {errors.careerBest && (
                            <Message negative>{errors.careerBest.message}</Message>
                        )}
                    </Form.Field>
                    <Form.Field className={"player-adc-qualifier"}>
                        <Label className='players-form-label'>ADC Qualifier</Label>
                        <Controller
                            name="adcQualifier"
                            control={control}
                            render={(props) => (
                                <Select
                                    value={props.value}
                                    clearable
                                    id="userRole"
                                    options={adcOptions}
                                    placeholder="select ADC Qualifier"
                                    onChange={(e, {value}) => props.onChange(value)}
                                />
                            )}
                        />
                    </Form.Field>
                </div>
                <div className={"submit-btn"}>
                    <Button type="submit" id="save_player" color="green">
                        {editMode ? "update" : "submit"}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default DartsPlayersTableForm;
