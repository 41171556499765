import React, {useEffect} from "react";
import {Table, Button, Icon, Pagination} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import DartsCompetitionsForm from "./DartsCompetitionsForm/DartsCompetitionsForm";
import CheckboxCms from "../../../../components/CheckboxCms/CheckboxCms";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    clearSingleCompetition,
    deleteDartsCompetition,
    getDartsCompetitions, getDartsSingleCompetition,
    setEditModeCompetitionForm, setPageNumber,
    showCompetitionForm
} from "../../../../store/cms/darts/competitions/competitionsSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";
import styles from "./style.module.scss"
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";

const DartsCompetitions = () => {
    const dispatch = useAppDispatch();
    const {
        dartsCompetitions,
        isVisibleForm,
        isLoading,
        noContent,
        pageNum,
        pageSize,
        totalPages,
    } = useAppSelector((state) => state.cmsCompetitions);
    const {user} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getDartsCompetitions(buildQueryString({pageNum, pageSize})));
    }, []);

    const openCompetitionForm = () => {
        dispatch(showCompetitionForm());
    }

    const updateCompetitionBtnHandler = (id: number) => {
        dispatch(getDartsSingleCompetition(id));
        dispatch(setEditModeCompetitionForm(id));
        dispatch(clearSingleCompetition());
    }

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum:activePage,pageSize}
        dispatch(getDartsCompetitions(buildQueryString(params)));
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button onClick={openCompetitionForm} id="add_competition" color="green">
                    Add competition
                </Button>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No competitions</div>
            ) : (
                <Table celled>
                    {isLoading && <DimmerLoader/>}
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">
                                    Id
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">
                                Competition name
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                ADC Qualifier
                            </Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dartsCompetitions?.map((competition) => {
                            return (
                                <Table.Row key={competition.id}>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">
                                            {competition.id}
                                        </Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">
                                        {competition.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <CheckboxCms value={competition.isADC} isDisabled={true} isReadyOnly={true}/>
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <div className={styles.tableControls}>
                                            <DartsDeleteModal
                                                id={competition.id!}
                                                deleteAction={(id: number) => dispatch(deleteDartsCompetition(id))}
                                                confirmText={"Are you sure you want to delete your competition?"}
                                                titleModal={"Delete your Competition"}
                                            />
                                            <Icon
                                                name='pencil alternate'
                                                link
                                                color="grey"
                                                size="small"
                                                onClick={() => updateCompetitionBtnHandler(competition?.id!)}
                                            >
                                            </Icon>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {isVisibleForm && <DartsCompetitionsForm/>}
            {!noContent && (
                <Pagination
                    floated="right"
                    size="mini"
                    activePage={pageNum}
                    totalPages={totalPages}
                    onPageChange={(e, {activePage}) => onChangePager(activePage)}
                />
            )}
        </div>
    );
};

export default DartsCompetitions;
